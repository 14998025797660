const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\forms\\FormSelect.js";import React, { useState } from "react";
import Parse from "parse";
import QrReader from "react-qr-scanner";
import { isIOS, isSafari } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import config from "../config";

import {
  UserOutlined,

  LogoutOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Menu,
  Modal,
  Dropdown,
  Button,
  PageHeader,
  Select,
  Divider,
  AutoComplete,
  Result,



} from "antd";
import FormList from "./FormList";
import "antd/dist/antd.css";

import Loading from "../components/Loading";
const { Option } = Select;

function FormSelect(props) {
  const [state, setState] = useState({
    modalVisible: false,
    delay: 500,
    result: "No result",
    stillLoading: true,
    reference: localStorage.getItem("openchecklistref") || null,
    unitFilter: ["all"],
  });
  const navigate = useNavigate();
  const getReferences = async () => {
    if (!config.references) return; //No Server Side Refs

    const query = new Parse.Query(config.references.table);
    query.limit(9999999);
    let references = await query.find();
    setState(
      produce((draft) => {
        draft.references = references.map((ref) => {
          let val = "";
          for (let part of config.references.label) {
            if (val !== "") {
              val += " - ";
            }
            val += ref.get(part);
          }
          return { value: val, key: ref.get(config.references.field) };
        });
      })
    );
  };

  const emtpyReference = (
    React.createElement(Result, {
      title: "Bitte wählen Sie einen Auftrag aus"     ,
      extra: 
        React.createElement(Button, {
          type: "primary",
          key: "console",
          onClick: () => {
            updateReferenceModal(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
        , "Auftrag festlegen"

        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
    )
  );
  const getFormsByUnit = async () => {
    setState(
      produce((draft) => {
        draft.stillLoading = true;
      })
    );

    //Nehme die aktuelle Auftragsnummer, die der Nutzer in das Feld eingetragen hat (state.reference)
    //Wenn eine eingetragen ist, gucke ob der aktuelle Wert mit dieser matched
    let x = null;
    if (!state.reference) {
      setState(
        produce((draft) => {
          draft.stillLoading = false;
        })
      );
      return emtpyReference;
    }

    const pUnits = await config.units.get(state.reference);
    const units = {};
    let first = true;

    for (let unit of pUnits) {
      let forms = await config.forms.get(unit, state.reference);

      forms = forms.map((form) => {
        let cForm = form.toJSON();
        cForm.description = Object.keys(cForm.form)
          .filter((key) => {
            return key.indexOf("step") === 0;
          })
          .sort((a, b) => {
            return (
              parseInt(a.replace("step", "")) - parseInt(b.replace("step", ""))
            );
          })
          .map((key) => {
            return cForm.form[key].title;
          })
          .join(", ");

        return cForm;
      });

      units[unit.id] = unit.toJSON();
      units[unit.id].forms = forms;
    }
    setState(
      produce((draft) => {
        draft.units = units;
        draft.stillLoading = false;
      })
    );
  };

  React.useEffect(() => {
    getReferences();
  }, []);
  React.useEffect(() => {
    getFormsByUnit();
  }, [state.reference]);

  if (state.stillLoading) return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}});

  const updateReferenceModal = async (modalVisible) => {
    setState(
      produce((draft) => {
        draft.modalVisible = modalVisible;
      })
    );
  };

  const handleScan = (data) => {
    if (data) {
      setState(
        produce((draft) => {
          draft.reference = data;
          localStorage.setItem("openchecklistref", data);
          draft.modalVisible = false;
        })
      );
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const menu = (
    React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
      , React.createElement(Menu.Item, {
        icon: React.createElement(LogoutOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 175}} ),
        onClick: () => {
          if (Parse.User.current()) {
            Parse.User.logOut();
            localStorage.clear();
            window.location = "/login";
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
      , "Logout"

      )
    )
  );
  if (!Parse.User.current()) {
    window.location = "/login";
    return;
  }
  const DropdownMenu = () => {
    return (
      React.createElement(Dropdown, { key: "more", overlay: menu, trigger: ["click"], __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}
        , React.createElement(Button, {
          style: {
            textAlign: "right",
          },
          icon: 
            React.createElement(UserOutlined, {
              style: {
                fontSize: 24,
                verticalAlign: "top",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
        
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}, Parse.User.current().get("username"))
        )
      )
    );
  };

  const qrCODEScanner = () => {
    if (config.qrEnabled && !isIOS && !isSafari && state.modalVisible) {
      return (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}})

          , React.createElement('div', { style: { height: "320px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
            , React.createElement(QrReader, {
              delay: state.delay,
              style: { height: 240, width: "100%" },
              onError: handleError,
              onScan: handleScan,
              showViewFinder: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
            )
          )
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 229}})
        )
      );
    } else {
      return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 233}});
    }
  };

  const UnitForms = (props) => {
    const options = Object.keys(state.units).map((key) => {
      return (
        React.createElement(Option, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
          , state.units[key][config.units.field]
        )
      );
    });

    const getChildren = (key, alreadyAdded) => {
      return Object.values(state.units)
        .filter((unit) => {
          return (
            unit[config.units.source].indexOf(key[[config.units.source]]) ==
              0 &&
            unit[config.units.source].split(".").length ===
              key[config.units.source].split(".").length + 1
          );
        })
        .map((child) => {
          alreadyAdded.push(child[config.units.source]);
          const children = getChildren(child[config.units.field], alreadyAdded);
          return {
            value: child.objectId,
            label: child[config.units.field],
            children: children.length > 0 ? children : void 0,
          };
        });
    };

    return (
      React.createElement('div', { key: "UnitList", __self: this, __source: {fileName: _jsxFileName, lineNumber: 268}}
        , React.createElement('div', {
          style: {
            backgroundColor: "#fff",
            marginBottom: "8px",
            paddingTop: "8px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 269}}
        
          , React.createElement(Select, {
            style: { width: "100%" },
            defaultValue: ["all"],
            value: state.unitFilter,
            placeholder: "Bitte auswählen" ,
            onChange: (value, text) => {
              setState(
                produce((draft) => {
                  draft.unitFilter = value;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 276}}
          
            , React.createElement(Option, { value: "all", key: "all", __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}, "Alle anzeigen"

            )
            , Object.keys(state.units).map((unitKey) => {
              return (
                React.createElement(Option, { value: unitKey, key: unitKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 294}}
                  , state.units[unitKey][config.units.field]
                )
              );
            })
          )
        )
        , Object.keys(state.units)
          .filter((filterKey) => {
            if (state.unitFilter.indexOf("all") === 0) return true;
            return filterKey.toLowerCase() === state.unitFilter.toLowerCase();
          })
          .map((key) => {
            return (
              React.createElement(FormList, { Unit: state.units[key], Reference: state.reference, __self: this, __source: {fileName: _jsxFileName, lineNumber: 308}} )
            );
          })
        , React.createElement('div', {
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
            padding: "5px",
            paddingTop: "15px",
            color: "gray",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
        
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 320}})
          , Object.keys(state.units).length > 1 ||
          Object.keys(state.units).length === 0
            ? "Insgesamt " +
              Object.keys(state.units).length +
              " Einheiten vorhanden"
            : "Insgesamt " +
              Object.keys(state.units).length +
              " Einheit vorhanden"
        )
      )
    );
  };

  return (
    React.createElement('div', { style: { backgroundColor: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}}
      , React.createElement('table', { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 336}}
        , React.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 337}}
          , React.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 338}}
            , React.createElement('td', { style: { textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 339}}
              , React.createElement(PageHeader, {
                title: "BDE",
                avatar: { src: "opensystems.png" },
                style: { background: "#fff", paddingBottom: "5px" },
                extra: [], __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}
              )
            )
            , React.createElement('td', { style: { textAlign: "right", paddingRight: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}
              , React.createElement(DropdownMenu, {
                style: { textAlign: "right", fontSize: 24 },
                key: "more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 348}}
              )
            )
          )
          , React.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 354}}
            , React.createElement('td', { colSpan: "2", style: { paddingBottom: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}}
              , React.createElement('div', {
                className: "headerpara",
                style: {
                  marginTop: "3px",
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 356}}
              
                , React.createElement(Button, {
                  type: "primary",
                  key: "ref_key_btn",
                  style: {
                    textAlign: "center",
                    border: "1px dotted",
                    borderColor: "darkgrey",
                    fontSize: "12pt",
                    width: "100%",
                  },
                  onClick: () => updateReferenceModal(true),
                  icon: 
                    React.createElement(EditOutlined, {
                      style: { fontSize: 24, verticalAlign: "top" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 377}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 365}}
                , "Auftrag: "

                  , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 383}}
                    , !state.reference || state.reference === 0
                      ? "nicht vorhanden"
                      : state.reference
                  )
                )
              )
            )
          )
        )
      )

      , state.reference && state.units ? React.createElement(UnitForms, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 395}} ) : emtpyReference

      , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}})

      , React.createElement(Modal, {
        title: "Auftragsnummer",
        style: { top: 20 },
        visible: state.modalVisible,
        onOk: () => updateReferenceModal(false),
        onCancel: () => updateReferenceModal(false),
        footer: [
          React.createElement(Button, {
            key: "back",
            onClick: () => {
              setState(
                produce((draft) => {
                  delete draft.reference;
                  delete draft.refCandidate;
                  localStorage.removeItem("openchecklistref");
                  draft.modalVisible = false;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 406}}
          , "Ohne Auftragsbezug"

          ),
          React.createElement(Button, {
            key: "submit",
            type: "primary",
            onClick: () => {
              let x;
              if (state.references) {
                x = state.references.find((ref) => {
                  return ref.value === state.refCandidate;
                });
              }
              setState(
                produce((draft) => {
                  if (x) {
                    draft.reference = x.key;
                  } else {
                    draft.reference = state.refCandidate;
                  }
                  delete draft.refCandidate;
                  localStorage.setItem("openchecklistref", draft.reference);
                  draft.modalVisible = false;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 421}}
          , "Speichern"

          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}
      
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 449}}, "Bitte geben Sie eine Auftragsnummer an:"     )
        /* <Input
          name="referenceinput"
          value={props.Reference}
          placeholder="A19876567"
          onChange={({ target: { value } }) => props.ChangeReference(value)}
       /> */

        , React.createElement(AutoComplete, {
          name: "referenceselect",
          options: state.references,
          dropdownClassName: "reference_input",
          placeholder: "Auftragsnummer eingeben oder auswählen..."   ,
          style: { width: "100%" },
          filterOption: (inputValue, option) => {
            return (
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            );
          },
          onChange: (e) => {
            const val = e;
            setState(
              produce((draft) => {
                draft.refCandidate = val;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 457}}
        )

        , qrCODEScanner()
      )
    )
  );
}
export default FormSelect;
