const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\forms\\components\\InfoMessage.js";import React, { } from "react";

import { Card, Typography } from "antd";
import "antd/dist/antd.css";

import { InfoCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

function InfoMessage(props) {
  let style = { width: "100%" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  let state = props.Data;

  return (
    React.createElement(Card, {
      title: state.title,
      extra: React.createElement(InfoCircleOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} ),
      bordered: true,
      style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, state.hint)
    )
  );
}
export default InfoMessage;
