const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\custom_wehn\\ArtikelBuchungDrawerComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse from "parse";
import produce from "immer";

import { useSwipeable } from "react-swipeable";
import {
  Space,
  Select,
  Button,
  InputNumber,
  Drawer,
  Divider,
  List,

  Modal,
  Result,
  Tabs,
  Badge,
  Input,
  Descriptions,
} from "antd";

import "antd/dist/antd.css";
import Loading from "../components/Loading";

import { Icon } from "@opendash/icons";

export default function ArtikelBuchungDrawerComponent(props) {
  const [state, setState] = React.useState({
    artikel: null,

    bestaende: {},
    selectedBestandId: undefined,
    einlagerungen: {},
    selectedEinlagerungId: undefined,

    seriennummer: "",
    charge: "",

    loading: true,

    currentTab: 1,
  });
  const [removeValue, setRemoveValue] = React.useState(1);

  const [restock, setRestock] = React.useState(null);
  const [restockAmount, setRestockAmount] = React.useState(1);

  // console.log("ArtikelBuchungDrawerComponent state", state);
  // console.log("ArtikelBuchungDrawerComponent props", props);

  const removals = props.removals || [];

  const update = (dataupdate) => {
    setState(
      produce((draft) => {
        draft.loading = true;
        draft.charge = "";
        draft.seriennummer = "";
      })
    );
    props.update(dataupdate);
  };

  const setCurrentTab = (tab) => {
    setState(
      produce((draft) => {
        draft.currentTab = tab;
      })
    );
  };

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.loading = false;
      })
    );
  }, [props.removals]);

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.loading = true;
        draft.currentTab = 1;
        draft.artikel = null;
        draft.seriennummer = "";
        draft.charge = "";
      })
    );
    if (props.selectedItem) {
      const mengeSoll =
        props.selectedItem.mengeSoll || props.selectedItem.menge;
      const mengeIst = props.selectedItem.mengeIst || 0;
      const mengeFehlt = Math.max(mengeSoll - mengeIst, 0);
      setRemoveValue(mengeFehlt);
      getLagerDetails();
    }
  }, [props.selectedItem]);

  const confirmRemoval = (item, bestandOrEinlagerung, amount) => {
    let ungeplant = false;

    if ("mengeSoll" in item && "mengeIst" in item) {
      if (item.mengeSoll <= item.mengeIst) {
        ungeplant = true;
      }
    }
    let icon = React.createElement(Icon, { icon: "fa:exclamation-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}} );
    let okButtonProps = {};
    if (!ungeplant) {
      icon = React.createElement(Icon, { icon: "fa:check-circle", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} );
      okButtonProps.style = {
        backgroundColor: "green",
        color: "white",
        borderColor: "darkgreen",
      };
    } else {
      icon = React.createElement(Icon, { icon: "fa:exclamation-circle", style: { color: "#faad14" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}} );
      okButtonProps.style = {
        backgroundColor: "#ffe58f",
        color: "#faad14",
        borderColor: "#faad14",
      };
    }
    const dateEntryStyle = { float: "right" };
    Modal.confirm({
      title: "Entnahme" + item.bezeichnung,
      icon,
      okButtonProps,
      okText: "" + amount + " " + _optionalChain([state, 'access', _ => _.artikel, 'optionalAccess', _2 => _2.mengeneinheit]) + " entnehmen",
      cancelText: "abbrechen",
      content: (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}, "Geplante Menge:"
             , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
              , item.mengeSoll || item.menge, " " , _optionalChain([state, 'access', _3 => _3.artikel, 'optionalAccess', _4 => _4.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 141}} )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}, "Bereits entnommen:"
             , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
              , item.mengeIst || 0, " " , _optionalChain([state, 'access', _5 => _5.artikel, 'optionalAccess', _6 => _6.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}} )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}, "Lager-Verfügbarkeit :"
             , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
              , bestandOrEinlagerung.menge ||
                bestandOrEinlagerung.bestandLaufend, " "
              , _optionalChain([state, 'access', _7 => _7.artikel, 'optionalAccess', _8 => _8.mengeneinheit])
            )
          )
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}})
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}, "Entnahme:"
            , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
              , amount, " " , _optionalChain([state, 'access', _9 => _9.artikel, 'optionalAccess', _10 => _10.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 164}} )
        )
      ),
      onOk() {
        const removal = produce(item, (draft) => {
          draft.lagerorte = [bestandOrEinlagerung];
          draft.menge = amount;
          if (ungeplant) draft.position = "ungeplant";
          if (state.seriennummer) {
            draft.seriennummer = state.seriennummer;
          }

          if (state.charge) {
            draft.charge = state.charge;
          }
        });

        let dataUpdate = {
          removals: [removal],
          restocked: [],
        };
        update(dataUpdate);
      },
    });
  };

  const getLagerDetails = async () => {
    const artikel = new Parse.Object("Artikel", {
      id: props.selectedItem.artikel.objectId,
    });

    await artikel.fetch();

    let bestaende = await new Parse.Query("ArtikelBestand")
      .equalTo("artikel", artikel)
      .greaterThan("bestandLaufend", 0)
      .include("lager")
      .find();

    let einlagerungen = await new Parse.Query("ArtikelEinlagerung")
      .equalTo("artikel", artikel)
      .greaterThan("menge", 0)
      .include("lager")
      .include("lagerort")
      .find();

    bestaende = Object.fromEntries(
      bestaende.map((parseObject) => [parseObject.id, parseObject.toJSON()])
    );
    einlagerungen = Object.fromEntries(
      einlagerungen.map((parseObject) => [parseObject.id, parseObject.toJSON()])
    );

    const selectedBestandId = Object.keys(bestaende)[0];

    // console.log("bestaende", bestaende);
    // console.log("einlagerungen", einlagerungen);
    // console.log("selectedBestandId", selectedBestandId);

    setState(
      produce((draft) => {
        draft.artikel = artikel.toJSON();
        draft.bestaende = bestaende;
        draft.selectedBestandId = selectedBestandId;
        draft.einlagerungen = einlagerungen;
        draft.selectedEinlagerungId = undefined;

        draft.loading = false;
        draft.stockPanel = "0";
      })
    );
  };
  const tabSwipeHandler = useSwipeable({
    onSwipedRight: (eventData) => {
      setCurrentTab(Math.max(state.currentTab - 1, 1));
    },
    onSwipedLeft: (eventData) => {
      setCurrentTab(Math.min(state.currentTab + 1, 2));
    },
  });

  const selectedBestand = React.useMemo(
    () => state.bestaende[state.selectedBestandId],
    [state.selectedBestandId]
  );

  const selectedEinlagerung = React.useMemo(
    () => state.einlagerungen[state.selectedEinlagerungId],
    [state.selectedEinlagerungId]
  );

  const bestaendeSelectOptions = React.useMemo(
    () =>
      Object.values(state.bestaende).map((x) => ({
        label: ` ${x.lager.sqlRef} - ${x.bestandLaufend} ${_optionalChain([state, 'access', _11 => _11.artikel, 'optionalAccess', _12 => _12.mengeneinheit])}`,
        value: x.objectId,
      })),
    [state.bestaende]
  );

  const einlagerungenSelectOptions = React.useMemo(
    () =>
      state.selectedBestandId
        ? Object.values(state.einlagerungen)
            .filter((x) => x.lager.objectId === selectedBestand.lager.objectId)
            .map((x) => ({
              label: `Lagerort ${x.lagerort.lagerort} - ${x.menge} ${_optionalChain([state, 'access', _13 => _13.artikel, 'optionalAccess', _14 => _14.mengeneinheit])}`,
              value: x.objectId,
            }))
        : [],
    [state.einlagerungen, selectedBestand]
  );

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.selectedEinlagerungId = _optionalChain([einlagerungenSelectOptions, 'access', _15 => _15[0], 'optionalAccess', _16 => _16.value]);
      })
    );
  }, [einlagerungenSelectOptions]);

  if (!props.selectedItem) {
    return null;
  }

  return (
    React.createElement(Drawer, {
      placement: "bottom",
      title: "Entnehmen von " + (_optionalChain([props, 'access', _17 => _17.selectedItem, 'optionalAccess', _18 => _18.artikelnr]) || ""),
      visible: !!props.selectedItem,
      closable: true,
      onClose: () => {
        props.onClose();
      },
      height: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
    
      , state.loading ? (
        React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 301}} )
      ) : (
        React.createElement('div', {
          size: "small",
          align: "center",
          direction: "vertical",
          style: { width: "100%" },
          ...tabSwipeHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
        
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 310}}, _optionalChain([props, 'access', _19 => _19.selectedItem, 'optionalAccess', _20 => _20.bezeichnung]))
          , React.createElement(Tabs, {
            activeKey: "" + state.currentTab,
            onTabClick: (key, event) => {
              setCurrentTab(parseInt(key));
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
          
            , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}, "Entnahme"), key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}
              , bestaendeSelectOptions.length === 0 && (
                React.createElement(Result, {
                  status: "warning",
                  title: "Artikel ist nicht mehr auf Lager"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}}
                )
              )

              , bestaendeSelectOptions.length > 0 && (
                React.createElement(Select, {
                  size: "large",
                  style: { width: "100%" },
                  options: bestaendeSelectOptions,
                  value: state.selectedBestandId,
                  onChange: (id) => {
                    setState(
                      produce((draft) => {
                        draft.selectedBestandId = id;
                      })
                    );
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 326}}
                )
              )

              , einlagerungenSelectOptions.length > 0 && (
                React.createElement(Select, {
                  size: "large",
                  style: { width: "100%", marginTop: 10 },
                  options: einlagerungenSelectOptions,
                  value: state.selectedEinlagerungId,
                  onChange: (id) => {
                    setState(
                      produce((draft) => {
                        draft.selectedEinlagerungId = id;
                      })
                    );
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
                )
              )

              , selectedBestand && (
                React.createElement(React.Fragment, null
                  , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 359}})
                  , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 360}}
                    , _optionalChain([state, 'access', _21 => _21.artikel, 'optionalAccess', _22 => _22.chargenverfolgung]) && (
                      React.createElement(Input, {
                        placeholder: "Chargennummer",
                        style: { width: "100%" },
                        value: state.charge,
                        onChange: (e) => {
                          const val = e.target.value;
                          setState(
                            produce((draft) => {
                              draft.charge = val;
                            })
                          );
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 362}}
                      )
                    )

                    , _optionalChain([state, 'access', _23 => _23.artikel, 'optionalAccess', _24 => _24.seriennummer]) && (
                      React.createElement(React.Fragment, null
                        , React.createElement(Input, {
                          placeholder: "Seriennummer",
                          style: { width: "100%" },
                          value: state.seriennummer,
                          onChange: (e) => {
                            const val = e.target.value;
                            setState(
                              produce((draft) => {
                                draft.seriennummer = val;
                              })
                            );
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 379}}
                        )
                        , React.createElement(Button, {
                          width: "100%",
                          type: "primary",
                          size: "large",
                          style: { width: "100%" },
                          onClick: () => {
                            confirmRemoval(
                              props.selectedItem,
                              selectedEinlagerung || selectedBestand,
                              1
                            );
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 392}}
                        , "Artikel mit Seriennummer entnehmen"

                        )
                      )
                    )

                    , !_optionalChain([state, 'access', _25 => _25.artikel, 'optionalAccess', _26 => _26.seriennummer]) && (
                      React.createElement(React.Fragment, null
                        , React.createElement(InputNumber, {
                          type: "number",
                          value: removeValue,
                          onChange: (test) => {
                            setRemoveValue(
                              Math.min(
                                test || 0,
                                _optionalChain([selectedEinlagerung, 'optionalAccess', _27 => _27.menge]) ||
                                  selectedBestand.bestandLaufend
                              )
                            );
                          },
                          placeholder: "Zur entnehmende Menge"  ,
                          style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 412}}
                        )

                        , React.createElement(Button, {
                          type: "primary",
                          size: "large",
                          style: { width: "100%" },
                          disabled: removeValue === 0,
                          onClick: () => {
                            confirmRemoval(
                              props.selectedItem,
                              selectedEinlagerung || selectedBestand,
                              removeValue
                            );
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 428}}
                        , "Entnehmen"

                        )
                      )
                    )
                  )
                )
              )
            )
            , React.createElement(Tabs.TabPane, {
              tab: 
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 451}}, "Bereits entommen"
                   , " "
                  , React.createElement(Badge, {
                    count: props.selectedItem.mengeIst || 0,
                    style: { backgroundColor: "#52c41a" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}
                  )
                )
              ,
              key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 449}}
            
              , React.createElement(List, {
                style: { background: "#fff", padding: "12px" },
                itemLayout: "vertical",
                dataSource: removals,
                renderItem: (item) => (
                  React.createElement(List.Item, {
                    actions: 
                      item.menge > 0
                        ? [
                            React.createElement(Button, {
                              onClick: () => {
                                console.log(item);

                                setRestock(item);
                                let dataUpdate = {
                                  restocked: [item],
                                  removals: [],
                                };
                                // update(dataUpdate);
                              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 470}}
                            , "wieder einlagern"

                            ),
                          ]
                        : []
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 466}}
                  
                    , React.createElement(List.Item.Meta, {
                      title: 
                        item.artikel
                          ? _optionalChain([item, 'access', _28 => _28.artikel, 'optionalAccess', _29 => _29.artikelnummer]) +
                            " - " +
                            _optionalChain([item, 'access', _30 => _30.artikel, 'optionalAccess', _31 => _31.bezeichnung])
                          : "Artikel"
                      ,
                      description: 
                        React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 497}}
                          , item.menge > 0
                            ? `Entnommen: ${Math.abs(item.menge)}`
                            : `Zurück eingelagert: ${Math.abs(item.menge)}`

                          , item.seriennummer && (
                            React.createElement(React.Fragment, null
                              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 504}} )
                              , `Seriennummer: ${item.seriennummer}`
                            )
                          )
                          , item.charge && (
                            React.createElement(React.Fragment, null
                              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 510}} )
                              , `Charge: ${item.charge}`
                            )
                          )
                        )
                      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 488}}
                    )
                  )
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 461}}
              )
            )
          )
        )
      )

      , React.createElement(Modal, {
        visible: !!restock,
        title: "Rücklagerung",
        onOk: () => {
          console.log(restock);
          console.log(restockAmount);

          update({
            restocked: [
              {
                objectId: restock.objectId,
                menge: restockAmount,
              },
            ],
            removals: [],
          });

          setRestock(null);
          setRestockAmount(1);
        },
        onCancel: () => {
          setRestock(null);
          setRestockAmount(1);
        },
        okText: "Einlagern",
        cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 525}}
      
        , restock && (
          React.createElement(React.Fragment, null
            , React.createElement(Descriptions, { layout: "horizontal", bordered: true, column: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 554}}
              , React.createElement(Descriptions.Item, {
                label: "Entnommen",
                children: 
                  Math.abs(restock.menge) + " " + _optionalChain([state, 'access', _32 => _32.artikel, 'optionalAccess', _33 => _33.mengeneinheit])
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 555}}
              )

              , restock.seriennummer && (
                React.createElement(Descriptions.Item, {
                  label: "Seriennummer",
                  children: restock.seriennummer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 563}}
                )
              )

              , restock.charge && (
                React.createElement(Descriptions.Item, { label: "Charge", children: restock.charge, __self: this, __source: {fileName: _jsxFileName, lineNumber: 570}} )
              )
            )

            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 574}} )

            , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 576}}, "Wie viel ("
                , _optionalChain([state, 'access', _34 => _34.artikel, 'optionalAccess', _35 => _35.mengeneinheit]), ") soll eingelagert werden?"
            )

            , React.createElement(InputNumber, {
              min: 1,
              max: restock.menge,
              value: restockAmount,
              onChange: (val) => {
                console.log(val);
                setRestockAmount(Math.max(1, Math.min(val, restock.menge)));
              },
              placeholder: "Menge",
              style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 580}}
            )
          )
        )
      )
    )
  );
}
