import Parse from "parse";

export default {
  qrEnabled: true,
  parse: {
    url: "https://parse-demo.apps.openinc.dev/parse",
    appid: "openinc",
  },
  units: {
    table: "OD3_BDE_Unit",
    field: "name",
    //filter: "auftrag",
    source: "source",
    get: async (ref) => {
      //let conf = await Parse.Config.get();
      //let forms = conf.get("forms");
      let formQuery = new Parse.Query("OD3_BDE_Unit");
      //formQuery.containedIn("RID", forms);
      return formQuery.find();
    },
  },
  forms: {
    table: "OD3_BDE_Form",
    get: async (unit, ref) => {
      if (!unit) throw Error("Unit needs to be provided");
      return unit.relation("forms").query().find();
    },
  },
  pages: {
    table: "OD3_BDE_Page",
  },
  lists: {
    table: "OD3_BDE_List",
    children: "OD3_BDE_ListEntry",
  },
  results: {
    table: "OD3_BDE_Result",
  },
};
