const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\Main.js";import React, { useEffect } from "react";
import Parse from "parse";
//import PullToRefresh from "pulltorefreshjs";
import { Layout, notification } from "antd";
import Forms from "./forms/Forms";
import FormSelect from "./forms/FormSelect";
import Login from "./components/Login";
import "antd/dist/antd.css";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import config from "./config";

Parse.serverURL = config.parse.url;
Parse.initialize(config.parse.appid, "");

function Main() {
  useEffect(() => {
    checkAuth();
  }, []);
  const navigate = useNavigate();
  const checkAuth = async () => {
    if (Parse.User.current()) {
      let user = Parse.User.current();
      await user.fetch();
      if (window.location.pathname.length < 2) {
        navigate("/select");
      }
    } else {
      if (!window.location.href.endsWith("/login")) window.location = "/login";
    }
  };

  const parseSaveResult = async (data) => {
    if (config.results.table) {
      if (!(data.formInfo.form && data.formInfo.unit)) return;
      try {
        let resultObject = {};
        resultObject.title = data.form.name;
        resultObject.saveDate = new Date().getTime();
        resultObject.formID = data.formInfo.form;
        resultObject.source = data.formInfo.unit;
        resultObject.reference = data.formInfo.reference;
        resultObject.user = Parse.User.current().toJSON();
        delete resultObject.user.sessionToken;
        delete resultObject.user.ACL;
        resultObject.user.username = Parse.User.current().getUsername();
        //resultObject.pages = { ...data.form.form };

        resultObject.pages = JSON.parse(JSON.stringify({ ...data.form.form }));

        resultObject.duration = 0;
        let keys = Object.keys(resultObject.pages).filter((key) => {
          return key.indexOf("step") == 0;
        });
        keys.forEach((step) => {
          resultObject.pages[step].fields.forEach((field) => {
            if (field.type.toLowerCase() !== "startstop") {
              field.value = data.fields[field.key];
            } else {
              field.value = [
                data.fields[field.key + "_start"],
                data.fields[field.key + "_duration"],
              ];
            }
          });
          resultObject.pages[step].startTS = 0;
          resultObject.pages[step].endTS = 0;
        });

        const RueckmeldeResult = Parse.Object.extend(config.results.table);
        const rueckmeldung = new RueckmeldeResult();
        rueckmeldung.set("result", resultObject);
        rueckmeldung.set("rueckmeldeUnit", resultObject.source);
        rueckmeldung.set("RID", resultObject.formID);
        rueckmeldung.save().then(
          (rueckmeldung) => {
            notification.success({
              message: "Gespeichert",
              description:
                "Eingetragenen Daten wurden erfolgreich im Backend gespeichert!",
            });
          },
          (error) => {
            notification.error({
              message: "Fehler beim Speichern",
              description:
                "Eingetragenen Daten konnten nicht übermittelt werden!",
            });
          }
        );
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Fehler beim Speichern",
          description: "Eingetragenen Daten konnten nicht übermittelt werden!",
        });
      }
    } else {
      console.warn("Data saving is disabled by config");
    }
  };

  //const navigate = useNavigate();
  return (
    React.createElement(Layout, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      , React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
        , React.createElement(Route, { path: "login", __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
          , React.createElement(Login, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 108}} )
        )
        , React.createElement(Route, { path: "select", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          , React.createElement(FormSelect, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} )
        )
        , React.createElement(Route, { path: "form/:unit/:RID/:entry/*", __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
          , React.createElement(Forms, {
            OnStepFinished: (index, step) => {},
            OnFormFinished: (result) => {
              parseSaveResult(result);
              navigate("/select");
            },
            OnCancel: () => {
              navigate("/select");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
          )
        )
      )
    )
  );
}

export default Main;
