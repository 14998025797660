const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\forms\\components\\SelectBox.js";import React, { } from "react";
import Parse from "parse";

import { Form, Select, Radio } from "antd";
import config from "../../config";
import "antd/dist/antd.css";



function SelectBox(props) {
  console.log(props);
  let style = {};
  const [items, setItems] = React.useState(props.Data.items || []);
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  let state = props.Data;
  let setValue = props.SetValue;
  let options = state.options || [];
  let rules = [];

  React.useEffect(() => {
    if (props.Data.list) {
      initList();
    }
  }, [props.Data.list]);
  const initList = async () => {
    try {
      const listObj = new Parse.Object(config.lists.table);
      listObj.id = state.list;
      const listOptions = await new Parse.Query(config.lists.children)
        .equalTo("list", listObj)
        .limit(999999)
        .find();
      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.get("key"),
            label: parseOption.get("value"),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  const typeSelect = () => {
    if (options.type) {
      if (options.type == "button") {
        return (
          React.createElement(Radio.Group, {
            style: style,
            options: items,
            optionType: "button",
            buttonStyle: "solid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          )
        );
      }
    }
    return React.createElement(Select, { style: style, options: items, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} );
  };

  return (
    React.createElement(Form.Item, { name: state.key, label: state.title, rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      , typeSelect()
    )
  );
}
export default SelectBox;
