const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\forms\\components\\StartStop.js";import React, { } from "react";
import Parse from "parse";
import produce from "immer";
import { useParams } from "react-router-dom";
import config from "../../config";
import {
  Button,
  Drawer,
  Radio,
  Select,
  Input,
  Form,
  Row,
  Col,
  Statistic,
  Result,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
moment.locale("de");
import "antd/dist/antd.css";

const { Option } = Select;
export default (props) => {
  const data = props.Data;
  const formData = props.FormData;
  const urlParams = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryparams = Object.fromEntries(urlSearchParams.entries());
  const [allowMultiStart, setAllowMultiStart] = React.useState(
    !!data.options.allowMultiStart
  );
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [now, setNow] = React.useState(new Date().getTime());
  const [events, setEvents] = React.useState({
    newEvent: {
      start: true,
      time: new Date().getTime(),
    },
    lastEvent: {
      start: false,
      time: new Date().getTime(),
    },
  });
  const getLastEventFromParse = async () => {
    let q = new Parse.Query(config.results.table);
    q.equalTo("RID", urlParams.RID);
    q.equalTo("rueckmeldeUnit", urlParams.unit);
    q.equalTo("result.user.username", Parse.User.current().getUsername());
    q.equalTo("result.reference", queryparams.reference);
    q.descending("createdAt");
    let last = (await q.first()).toJSON();
    if (last.result.pages) {
      Object.keys(last.result.pages)
        .filter((key) => key.indexOf("step") === 0)
        .forEach((key) => {
          console.log("key", key);
          let field = last.result.pages[key].fields.find(
            (field) => field.key === data.key
          );
          if (field) {
            console.log("Last Event", field);
            setEvents({
              lastEvent: {
                start: field.value[0],
                time: last.result.saveDate,
              },
              newEvent: {
                start: !field.value[0],
                time: new Date().getTime(),
              },
            });
            setNewEvent({});
          }
        });
    }
  };

  let rules = [];
  if (data["v_required"]) {
    rules.push({
      required: data["v_required"].value,
      message: data["v_required"].err,
    });
  }

  React.useEffect(() => {
    let x = {};
    x[data.key + "_start"] = events.newEvent.start;
    x[data.key + "_duration"] = events.newEvent.start
      ? 0
      : events.newEvent.time - events.lastEvent.time;
    formData.setFieldsValue(x);
  }, [events.newEvent]);

  React.useEffect(() => {
    if (events.newEvent.start === events.lastEvent.start) {
      setShowDrawer(true);
    }
  }, [events.newEvent.start, events.lastEvent.start]);
  React.useEffect(() => {
    getLastEventFromParse();
    let interval = setInterval(() => {
      setNow(new Date().getTime());
      setEvents(
        produce((draft) => {
          draft.newEvent.time = new Date().getTime();
        })
      );
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getHeader = () => {
    return (
      React.createElement(Row, { gutter: 16, style: { marginBottom: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        , React.createElement(Col, { span: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
          , React.createElement(Statistic, {
            title: "Aktuelle Uhrzeit" ,
            value: moment(now).format("LTS"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
          )
        )
        , React.createElement(Col, { span: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
          , events.lastEvent.start ? (
            React.createElement(Statistic, {
              title: "Begonnen um" ,
              value: moment(events.lastEvent.time).format("LTS"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
            )
          ) : (
            React.createElement(Statistic, { title: "Begonnen um" , value: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}} )
          )
        )
        , React.createElement(Col, { span: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
          , events.lastEvent.start ? (
            React.createElement(Statistic, {
              title: "Dauer",
              value: moment.duration(now - events.lastEvent.time).humanize(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
            )
          ) : (
            React.createElement(Statistic, { title: "Dauer", value: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}} )
          )
        )
      )
    );
  };

  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
      , React.createElement(Form.Item, {
        className: "hidden_custom_form",
        name: data.key + "_start",
        label: data.title,
        rules: rules,
        style: { marginBottom: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
      
        , React.createElement(Input, { style: { display: "none" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}} )
      )
      , React.createElement(Form.Item, {
        className: "hidden_custom_form",
        name: data.key + "_duration",
        rules: rules,
        style: { marginBottom: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
      
        , React.createElement(Input, { style: { display: "none" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}} )
      )
      , React.createElement('div', {
        className: "startstop_holder",
        style: { width: "100%", textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
      
        , getHeader()
        , React.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}, "Folgendes Ereignis melden"  )
        , React.createElement(Radio.Group, {
          style: { width: "100%" },
          value: events.newEvent.start,
          buttonStyle: "solid",
          onChange: (e) => {
            setEvents(
              produce((draft) => {
                draft.newEvent = {
                  start: e.target.value,
                  time: new Date().getTime(),
                };
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
        
          , !allowMultiStart || !events.lastEvent.start ? (
            React.createElement(Radio.Button, { style: { width: "50%" }, value: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}, "Start"

            )
          ) : (
            ""
          )
          , !allowMultiStart || events.lastEvent.start ? (
            React.createElement(Radio.Button, { style: { width: "50%" }, value: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}, "Stop"

            )
          ) : (
            ""
          )
        )
        , React.createElement('p', { className: "ant-statistic-title", style: { marginTop: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
          , React.createElement(InfoCircleOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 204}} ), " Meldung wird erst gesendet, wenn das Formular vollständig ausgefüllt und abgesendet wird!"

        )
        , React.createElement(Drawer, {
          visible: showDrawer,
          placement: "bottom",
          height: "80%",
          onClose: () => {
            setEvents(
              produce((draft) => {
                draft.newEvent.start = !draft.lastEvent.start;
              })
            );
            setShowDrawer(false);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
        
          , " "
          , React.createElement(Result, {
            title: "Achtung - Doppelte Statusmeldung"   ,
            subTitle: "Bitte bestätigen Sie die erneute Statusmeldung"     ,
            extra: 
              React.createElement(Button, {
                type: "primary",
                key: "console",
                onClick: () => {
                  setShowDrawer(false);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}
              , "Bestätigen"

              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
          )
        )
      )
    )
  );
};
