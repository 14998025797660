const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\forms\\components\\AutoComplete.js";import React, { } from "react";
import Parse from "parse";

import { Form, Select } from "antd";
import config from "../../config";
import "antd/dist/antd.css";

function AutoComplete(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  let state = props.Data;
  let setValue = props.SetValue;
  let options = state.options || [];
  const [items, setItems] = React.useState(state.items || []);
  let rules = [];
  const initList = async () => {
    try {
      const listObj = new Parse.Object(config.lists.table);
      listObj.id = state.list;
      const listOptions = await new Parse.Query(config.lists.children)
        .equalTo("list", listObj)
        .limit(999999)
        .find();
      console.log("Items:", listOptions);
      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.get("key"),
            label: parseOption.get("value"),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    if (state.list) {
      initList();
    }
  }, []);

  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }
  let mergedStyle = { ...{ heigt: "4em" }, ...style };
  console.log(mergedStyle);
  return (
    React.createElement(Form.Item, { name: state.key, label: state.title, rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      , React.createElement(Select, {
        className: "bde-autocomplete",
        showSearch: true,
        style: mergedStyle,
        options: items,
        listHeight: 100,
        filterOption: (input, option) =>
          JSON.stringify(option).toLowerCase().indexOf(input.toLowerCase()) >= 0
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
    )
  );
}
export default AutoComplete;
