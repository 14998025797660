const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\custom_wehn\\AuftragspositionComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse from "parse";
import produce from "immer";

import { createArtikelTransaction, undoArtikelTransaction } from "./common";
import Loading from "../components/Loading";
import {
  Alert,
  Button,
  Result,
  List,
  Row,
  Col,
  Space,
  Statistic,
  Badge,
} from "antd";

import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import "antd/dist/antd.css";
import ArtikelBuchungDrawerComponent from "./ArtikelBuchungDrawerComponent";

import { Icon } from "@opendash/icons";

export default function AuftragspositionComponent(props) {
  if (props.ShowNextButton()) {
    props.ShowNextButton(false);
  }

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = React.useState({
    position: undefined,
  });

  // console.log("AuftragspositionComponent params", params);
  // console.log("AuftragspositionComponent searchParams", searchParams);
  // console.log("AuftragspositionComponent props", props);
  // console.log("AuftragspositionComponent state", state);

  const save = async (data) => {
    for (let removal of data.removals) {
      await createArtikelTransaction(removal);
    }

    for (let restock of data.restocked) {
      await undoArtikelTransaction(restock.objectId, restock.menge);
    }

    await fetchAuftragsposition(params.unit);
  };
  const selectItem = (item) => {
    //O-Teil
    if (!item.artikel) return;

    //Position gesperrt
    //if (item.gesperrt) return;
    setState(
      produce((draft) => {
        draft.selectedItem = { ...item };
      })
    );
  };
  const [lagerOrte, setLagerOrte] = React.useState({});
  const getLagerOrt = async () => {
    const artikel = new Parse.Object("Artikel", {
      objectId: state.position.artikel.objectId,
    });
    let lagerQuery = new Parse.Query("ArtikelEinlagerung");
    lagerQuery.equalTo("artikel", artikel);
    lagerQuery.include("lager");
    lagerQuery.include("lagerort");
    let einlagerungen = await lagerQuery.find();

    let bestandQuery = new Parse.Query("ArtikelBestand");
    bestandQuery.include("lager");
    bestandQuery.equalTo("artikel", artikel);
    let bestaende = await bestandQuery.find();
    let result = {};

    for (const bestand of bestaende) {
      const artikelId = _optionalChain([bestand, 'access', _ => _.get, 'call', _2 => _2("artikel"), 'optionalAccess', _3 => _3.id]);
      const lagerNr = parseInt(_optionalChain([bestand, 'access', _4 => _4.get, 'call', _5 => _5("lager"), 'optionalAccess', _6 => _6.get, 'call', _7 => _7("sqlRef")]));

      result[artikelId] = {
        lagerNr,
        lager: bestand.get("lager").get("sqlRef"),
        lagerName: bestand.get("lager").get("bezeichnung"),
      };
    }
    for (const einlagerung of einlagerungen) {
      const artikelId = einlagerung.get("artikel").id;
      const lagerNr = parseFloat(
        JSON.parse(_optionalChain([einlagerung, 'access', _8 => _8.get, 'call', _9 => _9("lagerort"), 'optionalAccess', _10 => _10.get, 'call', _11 => _11("sqlRef")]) || "[0,0]").join(
          "."
        )
      );

      result[artikelId] = {
        lagerNr,
        lagerName: einlagerung.get("lager").get("bezeichnung"),
        lagerOrt:
          _optionalChain([einlagerung, 'access', _12 => _12.get, 'call', _13 => _13("lagerort"), 'optionalAccess', _14 => _14.get, 'call', _15 => _15("lagerort")]) || "Kein Lagerort",
        lager: einlagerung.get("lager").get("sqlRef"),
      };
    }
    setLagerOrte(result);
  };
  React.useEffect(() => {
    if (_optionalChain([state, 'access', _16 => _16.position, 'optionalAccess', _17 => _17.artikel])) {
      getLagerOrt();
    }
  }, [state.position]);

  React.useEffect(() => {
    fetchAuftragsposition(params.unit);
  }, [params.unit]);

  const getListItem = (item) => {
    let unconfirmedRemovals = 0;
    let precisionSoll = _optionalChain([("" + item.mengeSoll), 'access', _18 => _18.split, 'call', _19 => _19("."), 'access', _20 => _20[1], 'optionalAccess', _21 => _21.length]) || 0;
    let precisionIst = _optionalChain([("" + item.mengeIst), 'access', _22 => _22.split, 'call', _23 => _23("."), 'access', _24 => _24[1], 'optionalAccess', _25 => _25.length]) || 0;

    const gesperrt = (
      React.createElement(Alert, {
        message: "Position gesperrt" ,
        type: "error",
        showIcon: true,
        icon: React.createElement(Icon, { icon: "fa:ban", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
      )
    );
    const done = (
      React.createElement(Alert, {
        message: "Position vollständig entnommen"  ,
        type: "success",
        showIcon: true,
        icon: React.createElement(Icon, { icon: "fa:check-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
      )
    );
    const LItem = (
      React.createElement(List.Item, {
        className: item.gesperrt ? "item-disabled" : "",
        style: {
          borderRight:
            /*!list.removals[item.artikelnr] ||*/ item.mengeIst +
              unconfirmedRemovals ===
            0
              ? "5px solid #FFF"
              : item.mengeSoll === item.mengeIst + unconfirmedRemovals
              ? "5px solid #00cc00"
              : item.mengeSoll > item.mengeIst + unconfirmedRemovals
              ? "5px solid #fff040"
              : "5px solid #00cc00",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
      
        , React.createElement(Row, {
          onClick: () => {
            selectItem(item);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
        
          , React.createElement(Col, { span: 15, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
            , React.createElement(Space, { direction: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
              , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                , (!_optionalChain([lagerOrte, 'access', _26 => _26[_optionalChain([item, 'access', _27 => _27.artikel, 'optionalAccess', _28 => _28.objectId])], 'optionalAccess', _29 => _29.lagerNr])
                  ? "Kein Bestand"
                  : lagerOrte[_optionalChain([item, 'access', _30 => _30.artikel, 'optionalAccess', _31 => _31.objectId])].lager +
                    " - " +
                    lagerOrte[_optionalChain([item, 'access', _32 => _32.artikel, 'optionalAccess', _33 => _33.objectId])].lagerOrt
                )
                  .toString()
                  .replace(".", " - ")
              )
              , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}, item.artikelnr || "O-Teil")
            )
          )
          , React.createElement(Col, {
            span: 8,
            onClick: () => {
              selectItem(item);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
          
            , React.createElement(Space, {
              align: "center",
              style: {
                padding: "10px",
                width: "100%",
                //backgroundColor: "white",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
            
              , item.gesperrt && gesperrt
              , item.fertig && done
              , !(item.gesperrt || item.fertig) && (
                React.createElement(Statistic, {
                  title: "Entnahme",
                  value: " " + item.mengeIst.toFixed(precisionIst) || "0",
                  suffix: 
                    "/ " +
                    item.mengeSoll.toFixed(precisionSoll) +
                    " " +
                    (_optionalChain([item, 'access', _34 => _34.artikel, 'optionalAccess', _35 => _35.mengeneinheit]) || "")
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
                )
              )
            )
          )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}, item.bezeichnung)
        )
      )
    );
    if (
      item.mengeSoll > item.mengeIst &&
      !_optionalChain([lagerOrte, 'access', _36 => _36[_optionalChain([item, 'access', _37 => _37.artikel, 'optionalAccess', _38 => _38.objectId])], 'optionalAccess', _39 => _39.lagerNr])
    ) {
      return (
        React.createElement(Badge.Ribbon, { text: React.createElement(Icon, { icon: "fa:exclamation", __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}} ), color: "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}
          , LItem
        )
      );
    } else {
      return LItem;
    }
  };

  async function fetchAuftragsposition(objectId) {
    try {
      const auftragsposition = await new Parse.Query("Auftragsposition")
        .include("auftrag")
        .include("artikel")
        .get(objectId);

      const removals = await new Parse.Query("open_transaction_artikel")
        .include("artikel")
        .equalTo("auftragposition", auftragsposition)
        .descending("updatedAt")
        .find();

      if (!auftragsposition) {
        throw new Error("Position not found");
      }

      setState(
        produce((draft) => {
          draft.position = auftragsposition.toJSON();
          /*
          draft.position = {
            bezeichnung: auftragsposition.get("artikel").get("bezeichnung"),
            artikelnr: auftragsposition.get("artikel").get("artikelnummer"),
            menge: auftragsposition.get("mengeAngefordert") || 0,
            auftragsposition: auftragsposition.toJSON(),
            auftrag: auftragsposition.get("auftrag").toJSON(),
            artikel: auftragsposition.get("artikel").toJSON(),
          };*/
          if (draft.position.artikel) {
            draft.position.artikelnr = auftragsposition
              .get("artikel")
              .get("artikelnummer");
          }

          draft.removals = removals.map((parseObject) => parseObject.toJSON());

          delete draft.error;
        })
      );
    } catch (error) {
      console.error("StockRemovalPosition: Could not retrieve Position", error);

      setState(
        produce((draft) => {
          delete draft.position;
          draft.error = {
            status: "warning",
            title: "Position konnte nicht gefunden werden",
          };
        })
      );
    }
  }

  if (state.error) {
    return (
      React.createElement(Result, {
        status: state.error.status,
        title: state.error.title,
        extra: 
          React.createElement(Button, {
            type: "primary",
            key: "back",
            onClick: () => {
              navigate("/select");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}
          , "zurück"

          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}}
      )
    );
  }

  if (!state.position) {
    return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 304}} );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, {
        style: { background: "#fff", padding: "12px" },
        itemLayout: "vertical",
        dataSource: [state.position],
        renderItem: 
          (item) => {
            return getListItem(item);
          }

          /*
          <List.Item
            actions={[
              <Button
                type="primary"
                onClick={() => {
                  setState(
                    produce((draft) => {
                      draft.selectedItem = item;
                    })
                  );
                }}
                disabled={item.auftragsposition.mengeAngefordert === 0}
              >
                entnehmen
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                "" +
                item.artikel.artikelnummer +
                " - " +
                item.artikel.bezeichnung
              }
              description={
                <div>
                  <span
                    style={
                      item.auftragsposition.mengeBereitgestellt ===
                      item.auftragsposition.mengeBestelltKunde
                        ? { color: "green" }
                        : {}
                    }
                  >
                    {"Entnommen: "}
                    <b>{item.auftragsposition.mengeBereitgestellt}</b>
                  </span>{" "}
                  <br />
                  <span
                    style={
                      item.auftragsposition.mengeAngefordert === 0
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {"Verfügbar für Entnahme: "}{" "}
                    <b>{item.auftragsposition.mengeAngefordert}</b>
                  </span>
                </div>
              }
            />
            </List.Item>*/
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 309}}
      )

      , React.createElement(ArtikelBuchungDrawerComponent, {
        selectedItem: state.selectedItem,
        removals: state.removals,
        update: save,
        onClose: () => {
          setState(
            produce((draft) => {
              draft.selectedItem = undefined;
            })
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 374}}
      )
    )
  );
}
