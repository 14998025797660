const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\wehn-checklist\\src\\js\\custom_wehn\\AuftragsstuecklistenPositionComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse from "parse";
import produce from "immer";

import ArtikelBuchungDrawerComponent from "./ArtikelBuchungDrawerComponent";

import {
  Space,
  List,
  Alert,
  Statistic,
  Button,
  Input,
  Drawer,
  Col,
  Row,
  Select,
  Spin,
  Result,
  Badge,
} from "antd";

import { Icon } from "@opendash/icons";

import "antd/dist/antd.css";

export default function AuftragsstuecklistenPositionComponent(props) {
  console.log(props);
  const list = props.List;
  const [lagerOrte, setLagerOrte] = React.useState({});
  const currentList = list.objectId;
  const { Option } = Select;
  const [refreshing, setRefreshing] = React.useState(false);
  const [state, setState] = React.useState({
    showFilter: props.Filter.show,
    filter: props.Filter.value,
  });
  const [selection, setSelection] = React.useState(-1);
  const inputid = "input_field_filter" + parseInt(Math.random() * 1000);
  const selectItem = (item) => {
    //O-Teil
    if (!item.artikel) return;

    //Position gesperrt
    if (item.gesperrt) return;
    setState(
      produce((draft) => {
        draft.selectedItem = { ...item };
        draft.showFilter = false;
      })
    );
  };
  const getLagerOrte = async () => {
    const positions =
      selection < 0 ? list.positions : list.children[selection].positions;
    if ((_optionalChain([positions, 'optionalAccess', _ => _.length]) || 0) === 0) return;
    console.log("LagerPositionen", positions);
    let artikelArray = positions.map((position) => {
      return position.artikel
        ? new Parse.Object("Artikel", {
            objectId: position.artikel.objectId,
          })
        : void 0;
    });
    artikelArray = artikelArray.filter((artikelCandidate) => {
      return !!artikelCandidate;
    });

    let lagerQuery = new Parse.Query("ArtikelEinlagerung");
    lagerQuery.containedIn("artikel", artikelArray);
    // lagerQuery.include("lager");
    lagerQuery.include("lagerort");
    let einlagerungen = await lagerQuery.find();

    let bestandQuery = new Parse.Query("ArtikelBestand");
    bestandQuery.include("lager");
    bestandQuery.containedIn("artikel", artikelArray);
    let bestaende = await bestandQuery.find();
    let result = {};

    for (const bestand of bestaende) {
      const artikelId = _optionalChain([bestand, 'access', _2 => _2.get, 'call', _3 => _3("artikel"), 'optionalAccess', _4 => _4.id]);
      const lagerNr = parseInt(_optionalChain([bestand, 'access', _5 => _5.get, 'call', _6 => _6("lager"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("sqlRef")]));

      result[artikelId] = {
        lagerNr,
        lager: bestand.get("lager").get("sqlRef"),
        lagerName: bestand.get("lager").get("bezeichnung"),
      };
    }
    for (const einlagerung of einlagerungen) {
      const artikelId = einlagerung.get("artikel").id;
      const lagerNr = parseFloat(
        JSON.parse(_optionalChain([einlagerung, 'access', _9 => _9.get, 'call', _10 => _10("lagerort"), 'optionalAccess', _11 => _11.get, 'call', _12 => _12("sqlRef")]) || "[0,0]").join(
          "."
        )
      );

      result[artikelId] = {
        lagerNr,
        lagerName: einlagerung.get("lager").get("bezeichnung"),
        lagerOrt:
          _optionalChain([einlagerung, 'access', _13 => _13.get, 'call', _14 => _14("lagerort"), 'optionalAccess', _15 => _15.get, 'call', _16 => _16("lagerort")]) || "Kein Lagerort",
        lager: einlagerung.get("lager").get("sqlRef"),
      };
    }
    setLagerOrte(result);
  };

  React.useEffect(() => {
    getLagerOrte();
  }, [selection, list]);

  React.useEffect(() => {
    props.SetExtras([
      React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
        , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
          , React.createElement(Button, {
            style: { width: "80px" },
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}} ),
            key: "stockremoval_search",
            onClick: () => {
              setState(
                produce((draft) => {
                  draft.filter = "";
                  draft.showFilter = true;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
          )
        )
      ),
    ]);
  }, []);
  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.showFilter = props.Filter.show;
        draft.filter = props.Filter.value;
      })
    );
  }, [props.Filter]);
  const subscribe = async () => {
    let livequery = new Parse.Query("AuftragsstuecklistenPosition");
    let aufList = new Parse.Object("Auftragsstueckliste");
    aufList.id = props.List.objectId;
    livequery.equalTo("auftragsstueckliste", aufList);
    let subscription = await livequery.subscribe();
    const liveUdpate = (object) => {
      update({ removals: [], restocked: [] });
      setRefreshing(true);
    };
    subscription.on("update", liveUdpate);
    subscription.on("create", liveUdpate);
  };
  React.useEffect(() => {
    setRefreshing(false);
  }, [props.List]);
  React.useEffect(() => {
    subscribe();

    let keydownListener = (e) => {
      if (
        e.target.tagName.toUpperCase() === "INPUT" ||
        (e.key.length > 1 && e.key.toLowerCase() !== "unidentified")
      ) {
        return;
      } else {
        setState(
          produce((draft) => {
            //draft.filter = e.key.toLowerCase() === "unidentified" ? "" : e.key;
            draft.showFilter = true;
          })
        );

        e.preventDefault();
        let el = document.getElementById("input_filter_field");
        if (el) el.focus();
      }
    };
    document.addEventListener("keydown", keydownListener);

    return () => {
      document.removeEventListener("keydown", keydownListener);
    };
  }, []);

  const update = (dataupdate) => {
    props.Update(dataupdate);
  };
  const search = () => {
    let query1 = new Parse.Query("Artikel");

    query1.contains("artikelnummer", state.filter);
    let query2 = new Parse.Query("Artikel");
    query2.contains("bezeichnung", state.filter);
    const mainQuery = Parse.Query.or(query1, query2);
    mainQuery.find().then((res) => {
      let items = res.map((item) => {
        let pItem = {};
        pItem["bezeichnung"] = item.get("bezeichnung");
        pItem["auftragsstueckliste"] = { objectId: currentList };
        pItem["position"] = "ungeplant";
        pItem["artikelnr"] = item.get("artikelnummer");
        pItem["menge"] = 0;
        pItem["auftrag"] = list.auftrag;
        pItem["auftragsposition"] = list.position;
        pItem["artikel"] = {
          type: "__pointer",
          className: "Artikel",
          objectId: item.id,
        };
        return pItem;
      });
      setState(
        produce((draft) => {
          draft.unplannedItems = items;
          draft.filter = "";
          draft.showFilter = false;
        })
      );
    });
  };
  const getFilter = () => {
    if (state.showFilter && selection == -1) {
      return (
        React.createElement(Alert, {
          style: {
            position: "fixed",
            left: "0px",
            right: "0px",
            top: "0px",
            zIndex: "9999",
          },
          message: "Aktiver Filter" ,
          description: 
            React.createElement('div', { id: inputid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
              , React.createElement(Input, {
                id: "input_filter_field",
                value: state.filter,
                autoFocus: true,
                onChange: (e) => {
                  const val = e.target.value;
                  setState(
                    produce((draft) => {
                      draft.filter = val;
                    })
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}
              )
            )
          ,
          type: "info",
          closable: true,
          onClose: () => {
            setState(
              produce((draft) => {
                draft.filter = "";
                draft.showFilter = false;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
        )
      );
    }
    return "";
  };
  const getListItem = (item) => {
    let unconfirmedRemovals = 0;
    let precisionSoll = _optionalChain([("" + item.mengeSoll), 'access', _17 => _17.split, 'call', _18 => _18("."), 'access', _19 => _19[1], 'optionalAccess', _20 => _20.length]) || 0;
    let precisionIst = _optionalChain([("" + item.mengeIst), 'access', _21 => _21.split, 'call', _22 => _22("."), 'access', _23 => _23[1], 'optionalAccess', _24 => _24.length]) || 0;
    if (list.removals[item.artikelnr]) {
      list.removals[item.artikelnr].forEach((removal) => {
        if (!removal.gebucht) {
          unconfirmedRemovals += removal.menge;
        }
      });
    }

    const gesperrt = (
      React.createElement(Alert, {
        message: "Position gesperrt" ,
        type: "error",
        showIcon: true,
        icon: React.createElement(Icon, { icon: "fa:ban", __self: this, __source: {fileName: _jsxFileName, lineNumber: 286}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}
      )
    );
    const LItem = (
      React.createElement(List.Item, {
        className: item.gesperrt ? "item-disabled" : "",
        style: {
          paddingLeft: "3px",
          borderRight:
            /*!list.removals[item.artikelnr] ||*/ item.mengeIst +
              unconfirmedRemovals ===
            0
              ? "6px solid gray"
              : item.mengeSoll === item.mengeIst + unconfirmedRemovals
              ? "6px solid #00cc00"
              : item.mengeSoll > item.mengeIst + unconfirmedRemovals
              ? "6px solid #fff040"
              : "6px solid #00cc00",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
      
        , React.createElement(Row, {
          onClick: () => {
            selectItem(item);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 306}}
        
          , React.createElement(Col, { span: 15, __self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
            , React.createElement(Space, { direction: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}
              , React.createElement('div', { style: { paddingTop: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
                , React.createElement('h3', { style: { margin: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 314}}
                  , React.createElement('svg', {
                    viewBox: "64 64 896 896"   ,
                    focusable: "false",
                    'data-icon': "search",
                    width: "1em",
                    height: "1em",
                    fill: "currentColor",
                    'aria-hidden': "true", __self: this, __source: {fileName: _jsxFileName, lineNumber: 315}}
                  
                    , React.createElement('path', { d: "M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"                                                            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 324}})
                  )
                  , (!_optionalChain([lagerOrte, 'access', _25 => _25[_optionalChain([item, 'access', _26 => _26.artikel, 'optionalAccess', _27 => _27.objectId])], 'optionalAccess', _28 => _28.lagerNr])
                    ? " Kein Bestand"
                    : " " +
                      lagerOrte[_optionalChain([item, 'access', _29 => _29.artikel, 'optionalAccess', _30 => _30.objectId])].lager
                        .match(/.{1,3}/g)
                        .join("-") +
                      "/" +
                      lagerOrte[_optionalChain([item, 'access', _31 => _31.artikel, 'optionalAccess', _32 => _32.objectId])].lagerOrt
                        .match(/.{1,3}/g)
                        .join("-")
                  )
                    .toString()
                    .replace(".", " - ")
                )
              )
              , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
                , React.createElement('h3', { style: { margin: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
                  , React.createElement('svg', {
                    viewBox: "64 64 896 896"   ,
                    focusable: "false",
                    'data-icon': "search",
                    width: "1em",
                    height: "1em",
                    fill: "currentColor",
                    'aria-hidden': "true", __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
                  
                    , React.createElement('path', { d: "M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"                                                            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 352}})
                  )
                  , item.artikelnr ? " " + item.artikelnr : " O-Teil"
                )
              )
            )
          )
          , React.createElement(Col, {
            span: 8,
            onClick: () => {
              selectItem(item);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 359}}
          
            , React.createElement(Space, {
              align: "center",
              style: {
                padding: "10px",
                width: "100%",
                //backgroundColor: "white",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 365}}
            
              , item.gesperrt && gesperrt
              , !item.gesperrt && (
                React.createElement(Statistic, {
                  title: "Entnahme",
                  value: " " + item.mengeIst.toFixed(precisionIst) || "0",
                  suffix: 
                    "/ " +
                    item.mengeSoll.toFixed(precisionSoll) +
                    " " +
                    (_optionalChain([item, 'access', _33 => _33.artikel, 'optionalAccess', _34 => _34.mengeneinheit]) || "")
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 375}}
                )
              )
            )
          )
          , React.createElement('span', {
            style: {
              marginBottom: "4px",
              color: "rgba(0, 0, 0, 0.75)",
              fontSize: "13px",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 388}}
          
            , item.bezeichnung
          )
        )
      )
    );
    if (
      item.mengeSoll > item.mengeIst &&
      !_optionalChain([lagerOrte, 'access', _35 => _35[_optionalChain([item, 'access', _36 => _36.artikel, 'optionalAccess', _37 => _37.objectId])], 'optionalAccess', _38 => _38.lagerNr])
    ) {
      return (
        React.createElement(Badge.Ribbon, { text: React.createElement(Icon, { icon: "fa:exclamation", __self: this, __source: {fileName: _jsxFileName, lineNumber: 405}} ), color: "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 405}}
          , LItem
        )
      );
    } else {
      return LItem;
    }
  };
  return (
    React.createElement('div', { style: props.style, className: "wehn-entnahme-stuecklisten", __self: this, __source: {fileName: _jsxFileName, lineNumber: 414}}
      , getFilter()
      , 
        React.createElement(Spin, { spinning: refreshing, __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 418}})
        )
      
      , React.createElement(Select, {
        style: { width: "100%", marginBottom: "10px" },
        className: "stcklistselector",
        defaultValue: selection,
        onSelect: (val) => {
          setSelection(val);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 421}}
      
        , React.createElement(Option, { value: -1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 429}}, "Aktuelle Ebene: " + list.bezeichnung)

        , list.children
          ? list.children
              .sort((a, b) => a.BDENummer - b.BDENummer)
              .map((child, index) => {
                return (
                  React.createElement(Option, { style: { marginLeft: "10px" }, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 436}}
                    , child.bezeichnung
                  )
                );
              })
          : ""
      )
      , selection !== -1 && list.children && list.children[selection] ? (
        React.createElement(AuftragsstuecklistenPositionComponent, {
          SetExtras: props.SetExtras,
          Filter: { show: state.showFilter, value: state.filter },
          style: { marginLeft: "10px" },
          List: list.children[selection],
          Stocks: lagerOrte,
          Update: update, __self: this, __source: {fileName: _jsxFileName, lineNumber: 444}}
        )
      ) : (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}
          , React.createElement(List, {
            style: { background: "#fff", padding: "12px" },
            itemLayout: "vertical",
            locale: {
              emptyText: (
                React.createElement(Result, {
                  title: "Artikel in aktueller Stückliste nicht gefunden!"     ,
                  extra: 
                    React.createElement(Button, {
                      type: "primary",
                      key: "console",
                      onClick: () => {
                        if (state.showFilter) {
                          search();
                        } else {
                          setState(
                            produce((draft) => {
                              draft.showFilter = true;
                              draft.filter = "";
                            })
                          );
                        }
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 462}}
                    , "Artikel suchen und entnehmen"

                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 459}}
                )
              ),
            },
            dataSource: list.positions
              .filter((item) => {
                return (
                  JSON.stringify(item)
                    .toLowerCase()
                    .indexOf((state.filter || "").toLowerCase()) != -1
                );
              })
              .sort((item1, item2) => {
                let i1done = item1.mengeSoll <= item1.mengeIst;
                let i2done = item2.mengeSoll <= item2.mengeIst;

                if (i1done === i2done) {
                  const lager1 =
                    _optionalChain([lagerOrte, 'access', _39 => _39[_optionalChain([item1, 'optionalAccess', _40 => _40.artikel, 'optionalAccess', _41 => _41.objectId])], 'optionalAccess', _42 => _42.lagerNr]) || 0;
                  const lager2 =
                    _optionalChain([lagerOrte, 'access', _43 => _43[_optionalChain([item2, 'optionalAccess', _44 => _44.artikel, 'optionalAccess', _45 => _45.objectId])], 'optionalAccess', _46 => _46.lagerNr]) || 0;
                  return lager1 - lager2;
                }
                if (i1done && !i2done) {
                  return 1;
                } else {
                  return -1;
                }
              }),
            renderItem: (item) => {
              return getListItem(item);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 454}}
          )
          , React.createElement(ArtikelBuchungDrawerComponent, {
            selectedItem: state.selectedItem,
            removals: list.removals[_optionalChain([state, 'access', _47 => _47.selectedItem, 'optionalAccess', _48 => _48.artikelnr])] || [],
            update: (data) => {
              setState(
                produce((draft) => {
                  draft.selectedItem = undefined;
                })
              );
              update(data);
            },
            onClose: () => {
              setState(
                produce((draft) => {
                  draft.selectedItem = undefined;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 513}}
          )
          , React.createElement(Drawer, {
            placement: "bottom",
            title: "Ungeplante Entnahme",
            visible: state.unplannedItems,
            closable: "true",
            onClose: () => {
              setState(
                produce((draft) => {
                  delete draft.unplannedItems;
                })
              );
            },
            height: "80%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 532}}
          
            , React.createElement(List, {
              itemLayout: "vertical",
              dataSource: state.unplannedItems,
              renderItem: (item) => (
                React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 550}}
                  , React.createElement(List.Item.Meta, {
                    title: "" + item.artikelnr,
                    description: item.bezeichnung, __self: this, __source: {fileName: _jsxFileName, lineNumber: 551}}
                  )
                  , React.createElement(Button, {
                    onClick: () => {
                      setState(
                        produce((draft) => {
                          delete draft.unplannedItems;
                          draft.selectedItem = item;
                        })
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 555}}
                  , "entnehmen"

                  )
                )
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 546}}
            )
          )
        )
      )
    )
  );
}
