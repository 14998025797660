 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Parse from "parse";

import { Modal, message } from "antd";

export async function createArtikelTransaction(item) {
  const transaction = new Parse.Object("open_transaction_artikel");
  console.log(item);
  //Set Auftrag
  if (_optionalChain([item, 'access', _ => _.auftrag, 'optionalAccess', _2 => _2.objectId])) {
    transaction.set(
      "auftrag",
      new Parse.Object("Auftrag", { id: item.auftrag.objectId })
    );
  }

  if (_optionalChain([item, 'access', _3 => _3.auftragsstueckliste, 'optionalAccess', _4 => _4.auftrag, 'optionalAccess', _5 => _5.objectId])) {
    transaction.set(
      "auftrag",
      new Parse.Object("Auftrag", {
        id: item.auftragsstueckliste.auftrag.objectId,
      })
    );
  }

  //Set Position
  if (_optionalChain([item, 'access', _6 => _6.auftragsposition, 'optionalAccess', _7 => _7.objectId])) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.auftragsposition.objectId,
      })
    );
  }

  /*
  if (item.auftragsstueckliste?.auftragPosition?.objectId) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.auftragsstueckliste.auftragPosition.objectId,
      })
    );
  }
  */
  //Set Stückliste
  if (_optionalChain([item, 'access', _8 => _8.auftragsstueckliste, 'optionalAccess', _9 => _9.objectId])) {
    transaction.set(
      "auftragsstueckliste",
      new Parse.Object("Auftragsstueckliste", {
        id: item.auftragsstueckliste.objectId,
      })
    );
  }

  if (item.objectId && "auftragPosition" in item) {
    transaction.set(
      "auftragposition",
      new Parse.Object("Auftragsposition", {
        id: item.objectId,
      })
    );
  } else if (item.objectId) {
    transaction.set(
      "auftragsstuecklistenPosition",
      new Parse.Object("AuftragsstuecklistenPosition", {
        id: item.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _10 => _10.artikel, 'optionalAccess', _11 => _11.objectId])) {
    transaction.set(
      "artikel",
      new Parse.Object("Artikel", {
        id: item.artikel.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _12 => _12.lager, 'optionalAccess', _13 => _13.objectId])) {
    transaction.set(
      "lager",
      new Parse.Object("Lager", {
        id: item.lager.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _14 => _14.lagerort, 'optionalAccess', _15 => _15.objectId])) {
    transaction.set(
      "lagerort",
      new Parse.Object("Lagerort", {
        id: item.lagerort.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _16 => _16.lagerorte, 'optionalAccess', _17 => _17[0], 'optionalAccess', _18 => _18.lager, 'optionalAccess', _19 => _19.objectId])) {
    transaction.set(
      "lager",
      new Parse.Object("Lager", {
        id: item.lagerorte[0].lager.objectId,
      })
    );
  }

  if (_optionalChain([item, 'access', _20 => _20.lagerorte, 'optionalAccess', _21 => _21[0], 'optionalAccess', _22 => _22.lagerort, 'optionalAccess', _23 => _23.objectId])) {
    transaction.set(
      "lagerort",
      new Parse.Object("Lagerort", {
        id: item.lagerorte[0].lagerort.objectId,
      })
    );
  }

  if (item.seriennummer) {
    transaction.set("seriennummer", item.seriennummer);
  }

  if (item.charge) {
    transaction.set("charge", item.charge);
  }

  transaction.set("menge", item.menge);
  transaction.set("geplant", item["position"] !== "ungeplant");
  console.log(transaction.toJSON());
  await transaction.save();

  for (let i = 0; i < 10; i++) {
    await delay(1000);

    await transaction.fetch();

    if (transaction.get("gebucht")) {
      break;
    }
  }

  if (transaction.get("gebucht")) {
    if (transaction.get("successful")) {
      message.success("Erfolgreich gebucht");
    } else {
      Modal.error({
        title: "Fehler in der Buchung",
        content: _optionalChain([transaction, 'access', _24 => _24.get, 'call', _25 => _25("response"), 'optionalAccess', _26 => _26.answer]),
      });
    }
  } else {
    message.error("Auftrag wurde nicht gebucht");
  }

  return transaction;
}

export async function undoArtikelTransaction(objectId, menge) {
  const a = new Parse.Object("open_transaction_artikel", {
    id: objectId,
  });

  await a.fetch();

  const b = new Parse.Object("open_transaction_artikel");

  b.set("geplant", a.get("geplant"));
  if (a.get("auftragsstuecklistenPosition")) {
    let soll = a.get("auftragsstuecklistenPosition").get("mengeSoll") || 0;
    let ist = a.get("auftragsstuecklistenPosition").get("mengeIst") || 0;
    if (soll > ist) {
      b.set("geplant", true);
    } else {
      b.set("geplant", false);
    }
  }
  //b.set("geplant", false);
  b.set("artikel", a.get("artikel"));
  b.set("auftrag", a.get("auftrag"));
  b.set("seriennummer", a.get("seriennummer"));
  b.set("charge", a.get("charge"));
  b.set("auftragposition", a.get("auftragposition"));
  b.set("auftragsstueckliste", a.get("auftragsstueckliste"));
  b.set("auftragsstuecklistenPosition", a.get("auftragsstuecklistenPosition"));
  b.set("lager", a.get("lager"));
  b.set("lagerort", a.get("lagerort"));

  b.set("menge", (menge || a.get("menge") || 0) * -1);

  b.set("undo", a);

  await b.save();
}

function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}
